<template>
  <a-drawer width="85%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="Marketing Name" prop="name">
                <a-input v-model="queryParam.name" placeholder="Search Marketing Name" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="Available Tank Size" prop="tankSizeId">
                <a-select v-model="queryParam.tankSizeId" placeholder="-All Tank Capacity-">
                  <a-select-option :key="item.id" v-for="(item,index) in TankSizeList">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:sku:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:sku:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:sku:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:sku:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
<!--         :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"

        :pagination="false"
        :bordered="tableBordered"
      >
          <a-switch slot="status" slot-scope="text, record" checked-children="Enable" un-checked-children="Disable" @change="chageStatus($event,record)" v-model="record.status === 1" />
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:sku:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:sku:edit']">-->
<!--            <a-icon type="edit" />edit-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:sku:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['cigarette:sku:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
           <a-divider type="vertical" />
             <a @click="gotoBrandSku">
             <a-icon type="eye" />Brand Sku
          </a>

          <a-divider type="vertical" />
             <a @click="gotoBatch">
             <a-icon type="eye" />Batch
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-drawer>

</template>

<script>
import { pageSku,listSku, delSku,addSku,updateSku } from '@/api/cigarette/goodSku'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listConfig} from "@/api/cigarette/config";

export default {
  name: 'Sku',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      open: false,
      selectedRows: [],
      TankSizeList:[],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      goodsId:0,
      // 查询参数
      queryParam: {
        type: null,
        productType: null,
        tankSizeId: null,
        tankSizeValue: null,
        resistanceValue: null,
        maxOutputVoltage: null,
        otherOptionId: null,
        otherOptionValue: null,
        productModel: null,
        variantCode: null,
        name: null,
        url: null,
        status: null,
        picture: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Available Compatibility',
          dataIndex: 'type',
          align: 'center',
          customRender(t,r) {
            if(t === 0) {
              return 'OMNI NFC';//一次性
            } else if(t==1) {
              return 'OMNI Hub';//电池
            }
          }
        },
        // {
        //   title: '产品类型：0-一次性 1-电池 2-汽化器',
        //   dataIndex: 'productType',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '默认为0 自定义尺寸 否则关联{zb_public_config.id}',
        //   dataIndex: 'tankSizeId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Available Tank Size',
          dataIndex: 'tankSizeValue',
          align: 'center'
        },
        {
          title: 'Resistance Value',
          dataIndex: 'resistanceValue',
          align: 'center'
        },
        {
          title: 'Max Output Voltage',
          dataIndex: 'maxOutputVoltage',
          align: 'center'
        },
        // {
        //   title: '其他选项 否则关联{zb_public_config.id}',
        //   dataIndex: 'otherOptionId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Other Option',
          dataIndex: 'otherOptionValue',
          align: 'center'
        },
        {
          title: 'Product Model',
          dataIndex: 'productModel',
          align: 'center'
        },
        {
          title: 'Variant Code',
          dataIndex: 'variantCode',
          align: 'center'
        },
        {
          title: 'Marketing Name',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: 'Product Picture',
          dataIndex: 'url',
          align: 'center'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        // {
        //   title: '设置封面',
        //   dataIndex: 'picture',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getTankSize();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    gotoBatch() {
      this.open = false;
      this.$router.push("/goods/batch")
    },
    gotoBrandSku() {
      this.open = false;
      this.$router.push("/clientbrand/brandProduct")
    },
    chageStatus(e,record) {
      let newRecord = JSON.parse(JSON.stringify(record));
      newRecord.status = e ? 1 : 0;
      updateSku(newRecord).then(res => {
        if(res.success) {
          record.status = e ? 1 : 0;
        } else {
          this.$message.info(res.message);
        }
      })
      console.log(e)
      console.log(record)
    },
  getTankSize() {
  listConfig({
    type: 1,
    status: 1
  }).then(res => {
    if (res.success) {
      this.TankSizeList = res.data;
    }
  })
},
    onClose () {
      this.open = false
    },
    show(record) {
    this.goodsId = record.id;
    this.queryParam.goodsId = record.id;
      this.getList();
      this.open = true
    },
    /** 查询新-产品规格列表 */
    getList () {
      this.loading = true
     pageSku(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        productType: undefined,
        tankSizeId: undefined,
        tankSizeValue: undefined,
        resistanceValue: undefined,
        maxOutputVoltage: undefined,
        otherOptionId: undefined,
        otherOptionValue: undefined,
        productModel: undefined,
        variantCode: undefined,
        name: undefined,
        url: undefined,
        status: undefined,
        picture: undefined,
        pageNum: 1,
        pageSize: 10,
        goodsId:this.goodsId
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSku(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/goods-sku/export', {
            ...that.queryParam
          }, `新-产品规格_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
